<!--
 * @version: 1.0
 * @Date: 2023-05-04 18:48:33
 * @LastEditors: 杨慢慢
-->
<template>
  <a-form-item label="申请时是否显示" required>
    <a-switch v-model:checked="config.showTakeOut" :disabled="config?.allDisabled" />
  </a-form-item>
  <!-- <a-form-item label="日期类型" name="format">
    <a-radio-group name="radioGroup" v-model:value="config.items[0].props.format">
      <a-radio value="yyyy-MM-DD">年-月-日</a-radio>
      <br />
      <div style="height: 8px"></div>
      <a-radio value="yyyy-MM-DD HH:mm">年-月-日 时：分</a-radio>
    </a-radio-group>
  </a-form-item> -->
</template>

<script setup>
import { defineProps } from 'vue'
defineProps(['config'])
</script>

<style lang="less" scoped></style>
